import axios from 'axios'
import Swal from "sweetalert2"
import { VueCookieNext } from 'vue-cookie-next'

const $axios = axios.create({
  baseURL: '/api/',
  timeout: 10000,
  headers: { 'Content-Type': 'application/json' }
})

// Request Interceptor
$axios.interceptors.request.use(config => {
  config.headers.Authorization = 'Token ' + VueCookieNext.getCookie('token')
  return config
})

// Response Interceptor to handle errors
$axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      const code = error.response.status
      if (code === 500) {
        error.message = 'Ошибка сервера. Попробуйте обновить страницу.'
      } else if (code === 401) {
        error.message = 'Ошибка авторизации. Попробуйте заново ввести логин/пароль.'
      } else if (code === 406) {
        error.message = 'Запрос не прошел в ERP. Проверьте данные.'
      } else if (code === 400) {
        error.message = 'Не удалось отправить запрос. Проверьте данные.'
      }
      Swal.fire({ icon: 'error', text: error.message })
    }

    return Promise.reject(error)
  })

export default {

  fetchData(url, params = {}) {
    return $axios.get(url, {
      params, 'headers': {
        'x-forwarded-for': VueCookieNext.getCookie('client_ip')
      }
    })
      .then(response => response.data)
  },

  postData(url, data) {
    return $axios.post(url, data)
      .then(response => response.data)
  },

  login(agentId, password, captchaToken) {
    return $axios.post('auth', { agentId, password, 'g-recaptcha-response': captchaToken })
      .then(response => {
        VueCookieNext.setCookie('token', response.data.token)
        VueCookieNext.setCookie('partner', response.data.partner)
        return response.data.token
      })
  }
}
