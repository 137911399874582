<template>
  <div class="theme-bg">
    <loading
      v-model:active="isLoading"
      color="red"
      background-color="#000000"
      :width="100"
      :height="100"
      :opacity="0.7"
      loader="dots"
    />
    <div class="container-fluid h-100">
      <Top />
      <main class="main">
        <div class="mb-4">
          <div
            class="row align-items-center justify-content-between no-gutters"
          >
            <div class="col-md-5 col-xs-12 my-2 mr-3">
              <h1 class="sec-title">Заявки и заказы</h1>
            </div>
            <div
              class="col-md-3 col-xs-12 d-flex justify-content-md-end justify-content-xs-center align-items-center"
            >
              <div>
                <Datepicker
                  v-model="date"
                  :enableTimePicker="false"
                  :clearable="false"
                  :format-locale="locale"
                  :format="format"
                  locale="ru"
                  selectText="Выбрать"
                  cancelText="Отмена"
                  range
                ></Datepicker>
              </div>
              <div>
                <span @click="exportData" class="export-button"></span>
              </div>
            </div>
            <div class="search-col col-md-2 col-xs-12 my-2">
              <form action class="search-form" @submit.prevent="submitSearch">
                <input
                  type="search"
                  placeholder="Поиск..."
                  v-model.lazy="search"
                  @submit="submitSearch"
                  v-tippy="{
                    content: 'Введите запрос от 3 символов и нажмите Enter.',
                  }"
                />
                <input type="submit" value="Поиск" />
              </form>
            </div>
          </div>
        </div>
        <div class="table-wrap">
          <div class="table">
            <div v-if="resources.results[0]" class="tr tr--header">
              <div class="td table__number">Номер заявки</div>
              <div class="td table__date">Изменен</div>
              <div class="td table__city">Город</div>
              <div class="td table__device">Тип оборудования</div>
              <div class="td table__phone">Статус</div>
              <div class="td table__client">Тел. клиента</div>
              <div class="td table__status">Тел. звонка</div>
              <div class="td table__profit">Сумма</div>
            </div>
            <div v-else-if="!isLoading" class="tr">
              <div class="td table__empty">
                К сожалению, подходящих документов не нашлось.
              </div>
            </div>
            <div class="tr" v-for="doc in resources.results" :key="doc.id">
              <Doc :doc="doc" />
            </div>
          </div>
        </div>
        <v-pagination
          v-model="page"
          :pages="pages"
          :range-size="0"
          active-color="rgb(249, 12, 12)"
          @update:modelValue="fetchDocs"
        />
      </main>
    </div>
  </div>
</template>

<script>
import $backend from "../backend";
import Loading from "vue-loading-overlay";
import Doc from "@/components/Doc.vue";
import Top from "@/components/Top.vue";
import { VueCookieNext } from "vue-cookie-next";
import dayjs from "dayjs";
import { ru } from "date-fns/esm/locale";
import Datepicker from "@vuepic/vue-datepicker";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import * as Excel from "exceljs";
import { saveAs } from "file-saver";
import profit from "@/components/mixins/profit.js";
import phone from "@/components/mixins/phone.js";
import _ from "lodash";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  name: "docs",
  mixins: [profit, phone],
  data() {
    return {
      timer: "",
      search: null,
      totalCount: 0,
      searchableKeys: [
        ["id"],
        ["client_phone"],
        ["call_phone"],
        ["city", "name"],
        ["equipment", "name"],
        ["status", "name"],
        ["docs_repair_ids"],
        ["docs_subtype_names"],
        ["docs_status_names"],
      ],
      docsSearchFields: [
        { name: "docs_repair_ids", path: ["repair_id"] },
        { name: "docs_subtype_names", path: ["subtype", "name"] },
        { name: "docs_status_names", path: ["status", "name"] },
      ],
      isLoading: false,
      token: VueCookieNext.getCookie("token"),
      page: 1,
      limit: 10,
      pages: 1,
      date: [this.startDate(), new Date()],
      locale: ru,
      format: "dd/MM/yyyy",
      resources: {
        count: 0,
        next: null,
        previous: null,
        results: [],
      },
    };
  },
  created() {
    this.fetchDocs();

    if (!this.search) {
      this.timer = setInterval(this.fetchDocs, 60000);
    }
  },
  components: {
    Loading,
    Doc,
    Top,
    Datepicker,
    VPagination,
  },
  watch: {
    $route: "fetchDocs",
    date: "fetchDocs",
  },
  methods: {
    fetchDocs() {
      this.search = null;
      let offset = (this.page - 1) * this.limit;
      this.isLoading = true;

      if (!this.date[1]) {
        this.date[1] = this.date[0];
      }

      $backend
        .fetchData("statuses", {
          limit: this.limit,
          offset: offset,
          updated_gte: dayjs(this.date[0]).format("YYYY-MM-DD 00:00:00"),
          updated_lte: dayjs(this.date[1]).format("YYYY-MM-DD 23:59:00"),
        })
        .then((responseData) => {
          this.totalCount = this.resources.count;
          this.resources = responseData;
          this.pages = Math.ceil(this.resources.count / this.limit);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    exportData() {
      this.isLoading = true;
      let createDocFile = this.createDocFile;

      if (this.search) {
        this.createDocFile(this.resources.results);
      } else {
        $backend
          .fetchData("statuses", {
            limit: this.resources.count,
            offset: 0,
            updated_gte: dayjs(this.date[0]).format("YYYY-MM-DD 00:00:00"),
            updated_lte: dayjs(this.date[1]).format("YYYY-MM-DD 23:59:00"),
          })
          .then((responseData) => {
            createDocFile(responseData.results);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
      this.isLoading = false;
    },
    createDocFile(data) {
      const wb = new Excel.Workbook({ showFirstColumn: true });
      const ws = wb.addWorksheet("Заказы");

      ws.columns = [
        { header: "Номер заявки", key: "id" },
        { header: "Изменен", key: "updated" },
        { header: "Город", key: "city" },
        { header: "Тип оборудования", key: "equipment" },
        { header: "Статус", key: "status" },
        { header: "Тел. клиента", key: "client_phone" },
        { header: "Тел. звонка", key: "call_phone" },
        { header: "Сумма", key: "profit" },
      ];

      data.forEach((item) => {
        ws.addRow({
          id: item.id,
          updated: dayjs(item.updated).format("DD.MM.YYYY HH:mm:ss"),
          city: item.city.name,
          status: item.status.name,
          equipment: item.equipment ? item.equipment.name : "",
          client_phone: this.prepareClientPhone(item.client_phone),
          call_phone: item.call_phone,
          profit: this.getProfit(item.docs),
        });
      });

      ws.getRow(1).eachCell((cell) => {
        cell.font = {
          name: "Arial",
          bold: true,
        };
        cell.alignment = { vertical: "middle", horizontal: "center" };
      });

      wb.xlsx.writeBuffer().then(function (buffer) {
        const blob = new Blob([buffer], { type: "applicationi/xlsx" });
        saveAs(blob, "orders.xlsx");
      });
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
    submitSearch() {
      if (this.search.length >= 1 && this.search.length <= 3) return false;

      if (!this.search) {
        this.fetchDocs();
        return true;
      }

      $backend
        .fetchData("statuses", {
          limit: this.totalCount,
          offset: 0,
          updated_gte: dayjs(this.date[0]).format("YYYY-MM-DD 00:00:00"),
          updated_lte: dayjs(this.date[1]).format("YYYY-MM-DD 23:59:00"),
        })
        .then((responseData) => {
          this.resources.results = responseData.results.filter((res) => {
            this.docsSearchFields.forEach((field) => {
              if (res["docs"].length) {
                res[field["name"]] = res["docs"]
                  .map((doc) => {
                    return _.get(doc, field["path"]);
                  })
                  .join(",");
              } else {
                res[field["name"]] = null;
              }
            });

            return this.searchableKeys.some((key) => {
              let nestedVal = null;

              nestedVal = _.get(res, key);

              if (nestedVal == null) return false;

              return nestedVal
                .toString()
                .toLowerCase()
                .includes(this.search.toString().toLowerCase());
            });
          });

          this.resources.count = this.resources.results.length;
          this.pages = Math.ceil(this.resources.count / this.limit);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    startDate() {
      const date = new Date();
      return new Date(date.getFullYear(), date.getMonth() - 1, 1);
    },
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
};
</script>


<style>
.dp__theme_dark {
   --dp-background-color: rgba(0,0,0,.9);
   --dp-text-color: #ffffff;
   --dp-hover-color: #484848;
   --dp-hover-text-color: #ffffff;
   --dp-hover-icon-color: #959595;
   --dp-primary-color: #b20000;
   --dp-primary-text-color: #ffffff;
   --dp-secondary-color: #a9a9a9;
   --dp-border-color: #2d2d2d;
   --dp-menu-border-color: #2d2d2d;
   --dp-border-color-hover: #aaaeb7;
   --dp-disabled-color: #737373;
   --dp-scroll-bar-background: #212121;
   --dp-scroll-bar-color: #484848;
   --dp-success-color: #b20000;
   --dp-success-color-disabled: #428f59;
   --dp-icon-color: #959595;
   --dp-danger-color: #e53935;
   --dp-highlight-color: rgba(0, 92, 178, 0.2);
}
</style>