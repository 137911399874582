export default {
    name: "profit",
    methods: {
        getProfit(docs) {
            if (docs.length) {
                return docs
                    .reduce(
                        (total, obj) => parseFloat(obj.profit) + parseFloat(total),
                        0.0
                    )
                    .toFixed(2);
            }
            return "";
        }
    }
}