<template>
  <div class="theme-bg">
    <loading
      v-model:active="isLoading"
      color="red"
      background-color="#000000"
      :width="100"
      :height="100"
      :opacity="0.7"
      loader="dots"
    />
    <div class="container-fluid h-100">
      <Top />
      <main class="main">
        <div class="mb-4">
          <div
            class="row align-items-center justify-content-between no-gutters"
          >
            <div class="col-auto my-2 mr-3">
              <h1 class="sec-title">Связаться с менеджером</h1>
            </div>
          </div>
        </div>
        <div class="mb-4">
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <form
                @submit.prevent="checkForm"
                action="#"
                method="post"
                class="ws-form"
              >
                <div class="form-group row mb-2">
                  <label for="phone" class="col-4 col-form-label"
                    >Номер телефона</label
                  >
                  <div class="col-8">
                    <input
                      v-model="phone"
                      v-maska="'(###) ###-##-##'"
                      class="form-control"
                      autocomplete="off"
                      id="phone"
                    />
                    <span id="phoneHelpBlock" class="form-text text-muted"
                      >Формат: (926) 123-45-67.</span
                    >
                  </div>
                </div>
                <div class="form-group row mb-2">
                  <label for="message" class="col-4 col-form-label"
                    >Сообщение</label
                  >
                  <div class="col-8">
                    <textarea
                      v-model="message"
                      id="message"
                      cols="40"
                      rows="5"
                      class="form-control"
                      aria-describedby="messageHelpBlock"
                    ></textarea>
                    <span
                      id="messageHelpBlock"
                      class="form-text text-muted"
                    ></span>
                  </div>
                </div>
                <input type="hidden" name="partner" v-model="partner" />
                <div class="form-group row mt-4">
                  <div class="offset-4 col-8">
                    <button name="submit" type="submit" class="btn">
                      Отправить
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import $backend from "../backend";
import Loading from "vue-loading-overlay";
import { maska } from "maska";
import Top from "@/components/Top.vue";
import { VueCookieNext } from "vue-cookie-next";

export default {
  name: "contact",
  data() {
    return {
      isLoading: false,
      name: "",
      phone: "",
      message: "",
      partner: "",
    };
  },
  created() {
    this.getPartnerCode();
  },
  methods: {
    checkForm() {
      if (this.phone === "" || !/\d+$/.test(this.phone)) {
        this.$swal({ icon: "error", text: "Формат телефона неверный." });
      } else {
        this.send();
      }
    },
    send() {
      this.isLoading = true;
      $backend
        .postData("contact", {
          phone: this.phone,
          message: this.message,
          partner: this.partner,
        })
        .then((data) => {
          if (data.success == true) {
            this.$swal({
              icon: "success",
              text: "Принято! Ожидайте звонка",
            });
            this.reset();
          } else {
            this.$swal({
              icon: "error",
              text: "Проверьте данные формы",
            });
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    reset() {
      this.name = "";
      this.phone = "";
      this.message = "";
    },
    getPartnerCode() {
      this.partner = VueCookieNext.getCookie("partner");
    },
  },
  components: {
    Loading,
    Top,
  },
  directives: {
    maska,
  },
};
</script>
