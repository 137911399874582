<template>
  <div class="theme-bg">
    <loading
      v-model:active="isLoading"
      color="red"
      background-color="#000000"
      :width="100"
      :height="100"
      :opacity="0.7"
      loader="dots"
    />
    <div class="container-fluid h-100">
      <Top />
      <main class="main">
        <div class="mb-4">
          <div
            class="row align-items-center justify-content-between no-gutters"
          >
            <div class="col-auto my-2 mr-3">
              <h1 class="sec-title">API</h1>
            </div>
          </div>
        </div>
        <div class="mb-4">
          <div class="row align-items-center mb-5">
            <div class="col-md-auto col-xs-12 mb-2 mb-md-0 token-label">Ваш токен:</div>
            <div class="col-md-4 col-xs-12 mb-3 mb-md-0">
              <input
                class="token-field"
                :type="tokenFieldType"
                v-model="token"
                readonly
              />
            </div>
            <div class="col-md-2 col-xs-12 d-flex flex-row mb-2 mb-md-0">
              <div
                class="token-button"
                :class="visibiblity"
                @click="toggleToken"
                v-tippy="{ content: 'Показать/скрыть токен' }"
              ></div>
              <div
                class="token-button copy"
                :class="visibiblity"
                @click="copyToken"
                v-tippy="{ content: 'Скопировать токен' }"
              ></div>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-md-auto col-xs-12 mb-3 mb-md-0 token-label">Документация API:</div>
            <div class="col-md-2 col-xs-12">
              <a class="docs-button" :href="pdfLink" target="_blank"
                >Смотреть</a
              >
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import Top from "@/components/Top.vue";
import { VueCookieNext } from "vue-cookie-next";
import docsPdf from "@/assets/pdf/docsapi.pdf";

export default {
  name: "repaired",
  data() {
    return {
      isLoading: false,
      tokenVisible: false,
      tokenFieldType: "password",
      token: VueCookieNext.getCookie("token"),
      visibiblity: "show",
      pdfLink: docsPdf,
    };
  },
  methods: {
    toggleToken() {
      if (this.tokenFieldType == "password") {
        this.tokenFieldType = "text";
        this.visibiblity = "hide";
      } else {
        this.tokenFieldType = "password";
        this.visibiblity = "show";
      }
    },
    copyToken() {
      navigator.clipboard.writeText(this.token).then(
        () => {
          console.log("Async: Copying to clipboard was successful!");
        },
        function (err) {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },
  },
  components: {
    Loading,
    Top,
  },
};
</script>
