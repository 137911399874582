export default {
    name: "phone",
    methods: {
        prepareClientPhone(phone) {
            if (phone) {
                return phone.slice(0, -2) + '**';
            }
            return ''
        }
    }
}