import Swiper from 'swiper'

const container = '.advantages.swiper-container'
const options = {
  loop: false,
  init: false,
  speed: 800,
  slidesPerView: 'auto',
  slidesPerGroup: 2,
  spaceBetween: 20,
  pagination: {
    el: '.advantages .swiper-pagination',
    type: 'bullets',
    clickable: true
  },
  autoplay: {
    delay: 3000
  },
  breakpoints: {
    1199: {
      slidesPerGroup: 1
    }
  }
}

export const initSlider = () => {
  new Swiper(container, options).init()
}
