import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueTippy from 'vue-tippy'
import VueSweetalert2 from 'vue-sweetalert2'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import { VueCookieNext } from 'vue-cookie-next'
import store from './store'

const SwalOptions = {
  confirmButtonColor: 'red',
  cancelButtonColor: '#78857B'
}

VueCookieNext.config({ expire: '1d' })

createApp(App)
  .use(router)
  .use(store)
  .use(VueCookieNext)
  .use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_KEY })
  .use(VueTippy,
    {
      directive: 'tippy',
      component: 'tippy',
      componentSingleton: 'tippy-singleton'
    })
  .use(VueSweetalert2, SwalOptions)
  .mount('#app')
