<template>
  <div class="theme-bg">
    <loading
      v-model:active="isLoading"
      color="red"
      background-color="#000000"
      :width="100"
      :height="100"
      :opacity="0.7"
      loader="dots"
    />
    <div class="container-fluid h-100">
      <Top />
      <main class="main">
        <div class="mb-4">
          <div
            class="row align-items-center justify-content-between no-gutters"
          >
            <div class="col-auto my-2 mr-3">
              <h1 class="sec-title">Передать заказ</h1>
            </div>
          </div>
        </div>
        <div class="mb-4">
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <form
                @submit.prevent="checkForm"
                action="#"
                method="post"
                class="ws-form"
              >
                <input type="hidden" name="remoteIP" v-model="remoteIP" />
                <div class="form-group row mb-2">
                  <label class="col-4 col-form-label" for="name"
                    >Имя клиента</label
                  >
                  <div class="col-8">
                    <input
                      v-model="name"
                      id="name"
                      type="text"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="form-group row mb-2">
                  <label for="phone" class="col-4 col-form-label"
                    >Телефон клиента</label
                  >
                  <div class="col-8">
                    <input
                      v-model="phoneValue"
                      class="form-control"
                      autocomplete="off"
                      id="phone"
                    />
                  </div>
                </div>
                <div class="form-group row mb-2">
                  <label for="message" class="col-4 col-form-label"
                    >Сообщение к заказу</label
                  >
                  <div class="col-8">
                    <textarea
                      v-model="message"
                      id="message"
                      cols="40"
                      rows="5"
                      class="form-control"
                      aria-describedby="messageHelpBlock"
                    ></textarea>
                    <span id="messageHelpBlock" class="form-text text-muted"
                      >Пожелания клиента, адрес и т.п.</span
                    >
                  </div>
                </div>
                <div class="form-group row mt-4">
                  <div class="offset-4 col-8">
                    <button name="submit" type="submit" class="btn">
                      Отправить
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import $backend from "../backend";
import Loading from "vue-loading-overlay";
import Top from "@/components/Top.vue";
import axios from "axios";

export default {
  name: "lead",
  data() {
    return {
      isLoading: false,
      name: "",
      phone: "",
      phoneValue: "",
      message: "",
      remoteIP: "",
    };
  },
  mounted() {
    this.setRemoteIP();
  },
  watch: {
    phoneValue(newValue) {
      const regex =
        /(\+7|7|8)?[\s-]?\(?[489][0-9]{2}\)?[\s-]?[0-9]{3}[\s-]?[0-9]{2}[\s-]?[0-9]{2}/gm;
      let phoneInput = document.querySelector("#phone"),
        matches = regex.exec(newValue);

      if (matches) {
        phoneInput.classList.remove("error");
        phoneInput.classList.add("valid");

        let phone = matches[0].replace(/\D/g, "");
        this.phone = phone.slice(-10);
      } else {
        phoneInput.classList.remove("valid");
        phoneInput.classList.add("error");

        this.phone = "";
      }
    },
  },
  methods: {
    checkForm() {
      if (this.phone === "" || !/\d+$/.test(this.phone)) {
        this.$swal({ icon: "error", text: "Формат телефона неверный." });
      } else {
        this.send();
      }
    },
    setRemoteIP() {
      axios.get("/api/ipinfo").then((response) => {
        this.remoteIP = response.data.ip;
      });
    },
    send() {
      this.isLoading = true;
      $backend
        .postData("orders", {
          client_name: this.name,
          phone: this.phone,
          message: this.message,
          remoteIP: this.remoteIP,
        })
        .then((data) => {
          if (data.success == true) {
            this.$swal({
              icon: "success",
              text: "Принято, отлично!",
            });
            this.reset();
          } else {
            this.$swal({
              icon: "error",
              text: "Проверьте данные формы",
            });
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    reset() {
      this.name = "";
      this.phone = "";
      this.phoneValue = "";
      this.message = "";
    },
  },
  components: {
    Loading,
    Top,
  },
};
</script>
