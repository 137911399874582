<template>
  <div class="theme-bg">
    <loading
      v-model:active="isLoading"
      color="red"
      background-color="#000000"
      :width="100"
      :height="100"
      :opacity="0.7"
      loader="dots"
    />
    <div class="container-fluid h-100">
      <Top />
      <main class="main">
        <div class="mb-4">
          <div
            class="row align-items-center justify-content-between no-gutters"
          >
            <div class="col-auto my-2 mr-3">
              <h1 class="sec-title">Техника и города</h1>
            </div>
          </div>
        </div>
        <div class="mb-4">
          <div class="row mb-4">
            <div class="col-12 col-md-3">
              <v-select
                  v-if="data.cities"
                  v-model="selected"
                  :clearable="false"
                  :options="[{label: 'Все города', value: 0}].concat(data.cities.filter((c) => c.id > 0).map((c) => ({label: c.name, value: c.id})))"
                  :reduce="(o) => o.value"
                  class="select-city"
              >
                <template #open-indicator="{ icon }">
                  <span v-bind="icon">
                    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg" class="vs__open-indicator">
                      <path d="M9 5L5 1L1 5" stroke="#1B1B44"/>
                    </svg>
                  </span>
                </template>
              </v-select>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="repaired-wrapper">
                <div v-if="data.cities" :class="'repaired-table ' + (selected > 0 ? 'repaired-table--selected' : '')">
                  <div
                      class="repaired-table__head">
                      <div class="repaired-empty"></div>
                      <div
                        v-for="city in data.cities.filter((c) => selected > 0 ? c.id === selected : true)"
                        :key="city.id"
                        v-bind:data-city="city.id"
                        class="th-city"
                      >
                        {{ city.name }}
                      </div>
                  </div>
                  <div
                      class="repaired-table__row"
                      v-for="eq in data.data.filter((e) => selected > 0 ? e.availability.filter((eq) => eq.city_id === selected && eq.status).length : true)"
                      :key="eq">
                    <div class="repaired-equipment">
                      <span>{{ eq.equipment }}</span>
                    </div>
                    <div
                      v-for="aval in eq.availability.filter((e) => selected > 0 ? e.city_id === selected : true)"
                      :key="aval"
                      class="repaired-available"
                    >
                      <div v-if="aval.status" class="available"></div>
                      <div v-else-if="!aval.status">&mdash;</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import Top from "@/components/Top.vue";
import $backend from "../backend";
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

export default {
  name: "repaired",
  data() {
    return {
      isLoading: false,
      selected: 0,
      data: [],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.isLoading = true;

      $backend
        .fetchData("equipment")
        .then((responseData) => {
          this.data = responseData;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  components: {
    Loading,
    Top,
    vSelect
  },
};
</script>