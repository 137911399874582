import { createStore } from "vuex"
import { VueCookieNext } from 'vue-cookie-next'

export default createStore({
  state: {
    isLogged: false
  },
  mutations: {
    login(state) {
      state.isLogged = true
    },
    logout(state) {
      VueCookieNext.removeCookie('token')
      state.isLogged = false
    }
  },
  actions: {
    logout({ commit }) {
      commit('logout')
    },
    login({ commit }) {
      commit('login')
    }
  }
})
