<template>
  <div class="theme-bg">
    <div class="container-fluid h-100">
      <div class="row no-gutters flex-column mh-100vh">
        <header class="header">
          <div class="row justify-content-between align-items-center text-center text-sm-left">
            <div class="col-sm-auto my-2">
              <a href="/"  class="logo"><img src="~@/assets/img/logo.svg" alt="" /></a>
            </div>
          </div>
        </header>
        <main class="main main--404">
          <div class="text-center">
            <h1 class="sec-title my-3">Не найдено</h1>
            <p class="mb-4">Вы запросили страницу, которой не существует.</p>
            <div class="btn-wrap">
              <router-link :to="{ name: 'docs' }" class="btn">К списку заказов</router-link>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>
