<template>
  <div>
    <div class="tr row" @click="toggleDocs($event, doc.docs)" :class="doc.docs.length ? 'pointer' : ''">
      <div class="td table__number">
        <span class="" :class="{ icon: doc.docs[0] }">{{ doc.id }}</span>
      </div>
      <div class="td table__date">{{ formatDate(doc.updated) }}</div>
      <div class="td table__date">{{ doc.city.name }}</div>
      <div class="td table__date">{{ getPropertyName(doc.equipment) }}</div>
      <div class="td table__date">{{ doc.status.name }}</div>
      <div class="td table__date">{{ prepareClientPhone(doc.client_phone) }}</div>
      <div class="td table__date">{{ doc.call_phone }}</div>
      <div class="td table__profit">{{ getProfit(doc.docs) }}</div>
    </div>
    <div class="tr tr--more">
      <div v-if="doc.docs[0]">
        <div class="table table-toggle">
          <div class="tr">
            <div class="td table__number">Номер заказа</div>
            <div class="td table__date">Создан</div>
            <div class="td table__device">Изменен</div>
            <div class="td table__phone">Статус</div>
            <div class="td table__client">Прибыль</div>
          </div>
          <div class="tr" v-for="repairDoc in doc.docs" :key="repairDoc.repair_id">
            <div class="td table__date">{{ repairDoc.repair_id }}</div>
            <div class="td table__date">
              {{ formatDate(repairDoc.created) }}
            </div>
            <div class="td table__date">
              {{ formatDate(repairDoc.updated) }}
            </div>
            <div class="td table__date">{{ repairDoc.status.name }}</div>
            <div class="td table__date">{{ repairDoc.profit }}</div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="td">Нет заказов</div>
      </div>
    </div>
  </div>
</template>

<script>
import * as dayjs from "dayjs";
import "dayjs/locale/ru";
import profit from "@/components/mixins/profit.js";
import phone from "@/components/mixins/phone.js";

export default {
  name: "Doc",
  mixins: [profit, phone],
  props: {
    doc: null,
    availableSubtypes: null,
  },
  data() {
    return {};
  },
  methods: {
    formatDate(date) {
      return dayjs(date).format("DD.MM.YYYY HH:mm:ss");
    },
    getPropertyName(prop) {
      return prop !== null ? prop.name : "";
    },
    toggleDocs(event, docs) {
      if (docs.length > 0) event.currentTarget.classList.toggle("active");
    },
  },
};
</script>